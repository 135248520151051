import { Injectable } from '@angular/core';
import { SQLite, SQLiteObject } from '@ionic-native/sqlite/ngx';
import { Platform } from '@ionic/angular';
import { Queue } from '../../classes/queue/queue';
import { Assignment } from '../../models/assignment';
import { AssignmentDetails } from '../../models/assignment-details';
import { DateService } from '../date/date.service';
import { LogService } from '../log/log.service';
import { Tables } from './sql.tables';

@Injectable({
  providedIn: 'root'
})
export class SqlService {
  // SqlLite settings
  private db: SQLiteObject;
  private dbName = 'brains_app.db';
  private dbLocation = 'default';

  // Debug helper
  private logTag = 'SqlService';

  constructor(private sqlite: SQLite, private platform: Platform, private logService: LogService, private dateService: DateService) {}

  /**
   * Initialize local SqlLiteDB.
   */
  public init(): void {
    // Run when device is ready
    this.platform.ready().then(() => {
      // Create db
      this.sqlite
        .create({
          name: this.dbName,
          location: this.dbLocation
        })
        .then((db: SQLiteObject) => {
          if (db) {
            // Save db connection
            this.db = db;
            // Create database tables if not exists
            db.executeSql(
              `CREATE TABLE IF NOT EXISTS ${Tables.assignment}
               (
                 id primary key,
                 date text,
                 data text
               )`
            ).catch((error) => {
              this.logService.info(this.logTag, 'init');
              this.logService.object(error);
            });
            db.executeSql(
              `CREATE TABLE IF NOT EXISTS ${Tables.assignmentDetails}
               (
                 id primary key,
                 data text
               )`
            ).catch((error) => {
              this.logService.info(this.logTag, 'init');
              this.logService.object(error);
            });
            db.executeSql(
              `CREATE TABLE IF NOT EXISTS ${Tables.queue}
               (
                 id primary key,
                 queue text
               )`
            ).catch((error) => {
              this.logService.info(this.logTag, 'init');
              this.logService.object(error);
            });
          }
        });
    });
  }

  /**
   * Get assignments from SqlLiteDB.
   * @param startDate The date to get assignments for
   */
  public getAssignments(startDate: Date): Promise<Assignment[]> {
    const startDateString = this.dateService.getDateAsString(startDate);
    return new Promise<Assignment[]>((resolve, error) => {
      this.sqlite
        .create({
          name: this.dbName,
          location: this.dbLocation
        })
        .then(async (db: SQLiteObject) => {
          if (db) {
            const results = [];
            db.executeSql(
              `SELECT *
               FROM ${Tables.assignment}
               WHERE date = ?`,
              [startDateString]
            ).then((resultSet) => {
              for (let x = 0; x < resultSet.rows.length; x++) {
                const result = JSON.parse(resultSet.rows.item(x).data);
                results.push(result);
              }
              if (results.length > 0) {
                resolve(results);
              } else {
                resolve(undefined);
              }
            });
          }
        })
        .catch((err) => {
          error(err);
          this.logService.error(this.logTag, 'getAssignments error');
          this.logService.object(err);
        });
    });
  }

  public getAssignmentById(assignmentId: number): Promise<any> {
    return new Promise<any>((resolve, error) => {
      this.sqlite
        .create({
          name: this.dbName,
          location: this.dbLocation
        })
        .then(async (db: SQLiteObject) => {
          if (db) {
            db.executeSql(
              `SELECT *
               FROM ${Tables.assignment}
               WHERE id = ${assignmentId}`
            )
              .then(
                (x) => {},
                (resultSet) => {
                  const returnItem = resultSet.rows.item(0);
                  if (returnItem) {
                    resolve(JSON.parse(returnItem.data));
                  } else {
                    error('Not Found');
                  }
                }
              )
              .catch((err) => {
                error(err);
              });
          }
        });
    });
  }

  /**
   * Save assignment to local SqlLiteDB.
   * @param assignment Assignment to save
   */
  public insertAssignment(assignment: Assignment): Promise<any> {
    return new Promise<any>((resolve, error) => {
      const data = JSON.stringify(assignment);
      const startDate = this.dateService.getDateAsString(assignment.startDate);
      if (this.db) {
        this.db
          .executeSql(
            `INSERT INTO ${Tables.assignment} (id, date, data)
             VALUES (?, ?, ?)`,
            [assignment.id, startDate, data]
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            error(err);
            this.logService.error(this.logTag, 'insertAssignment error');
            this.logService.object(err);
          });
      }
    });
  }

  /**
   * Update assignment in local SqlLiteDB.
   * @param assignment Assignment to update
   */
  public updateAssignment(assignment: Assignment): Promise<any> {
    return new Promise<any>((resolve, error) => {
      const data = JSON.stringify(assignment);
      if (this.db) {
        this.db
          .executeSql(
            `UPDATE ${Tables.assignment}
             SET data = ?
             WHERE id = ?`,
            [data, assignment.id]
          )
          .then((res) => {
            this.logService.info(this.logTag, 'updateAssignment() res:');
            this.logService.object(res);
            resolve(res);
          })
          .catch((err) => {
            this.logService.info(this.logTag, 'updateAssignment() err:');
            this.logService.object(err);
            error(err);
          });
      }
    });
  }

  /**
   * Delete assignment from local SqlLiteDB.ƒ
   * @param assignmentId Id of assignment to delete
   */
  public deleteAssignment(assignmentId: number): Promise<any> {
    return new Promise<any>((resolve, error) => {
      if (this.db) {
        this.db
          .executeSql(
            `DELETE
             FROM ${Tables.assignment}
             WHERE id = ?`,
            [assignmentId]
          )
          .then((res) => {
            this.logService.info(this.logTag, 'deleteAssignment() res:');
            this.logService.object(res);
            resolve(res);
          })
          .catch((err) => {
            this.logService.info(this.logTag, 'deleteAssignment() err:');
            this.logService.object(err);
            error(err);
          });
      }
    });
  }

  /**
   * Get assignment details by given id from SqlLiteDB.
   * @param assignmentId Id of assignment
   */
  public getAssignmentDetailsById(assignmentId: number): Promise<any> {
    return new Promise<any>((resolve, error) => {
      this.sqlite
        .create({
          name: this.dbName,
          location: this.dbLocation
        })
        .then(async (db: SQLiteObject) => {
          if (db) {
            db.executeSql(
              `SELECT *
               FROM ${Tables.assignmentDetails}
               WHERE id = ${assignmentId}`
            )
              .then(
                (x) => {},
                (resultSet) => {
                  const returnItem = resultSet.rows.item(0);
                  if (returnItem) {
                    resolve(JSON.parse(returnItem.data));
                  } else {
                    error('Not Found');
                  }
                }
              )
              .catch((err) => {
                error(err);
              });
          }
        });
    });
  }

  /**
   * Insert assignment details to local SqlLiteDB.
   * @param assignmentDetails Assignment to insert
   */
  public insertAssignmentsDetails(assignmentDetails: AssignmentDetails): Promise<any> {
    return new Promise<any>((resolve, error) => {
      const assignmentDetailsAsString = JSON.stringify(assignmentDetails);
      if (this.db) {
        this.db
          .executeSql(
            `INSERT INTO ${Tables.assignmentDetails} (id, data)
             VALUES (?, ?)`,
            [assignmentDetails.id, assignmentDetailsAsString]
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            error(err);
          });
      }
    });
  }

  /**
   * Update assignment details in local SqlLiteDB.
   * @param assignmentDetails Assignment to update
   */
  public updateAssignmentsDetails(assignmentDetails: AssignmentDetails): Promise<any> {
    return new Promise<any>((resolve, error) => {
      const assignmentDetailsAsString = JSON.stringify(assignmentDetails);
      if (this.db) {
        this.db
          .executeSql(
            `UPDATE ${Tables.assignmentDetails}
             SET data = ?
             WHERE id = ?`,
            [assignmentDetailsAsString, assignmentDetails.id]
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            error(err);
          });
      }
    });
  }

  /**
   * Delete assignment details from local SqlLiteDB.
   * @param assignmentId Id of assignment details to delete
   */
  public deleteAssignmentsDetails(assignmentId: number): Promise<any> {
    return new Promise<any>((resolve, error) => {
      if (this.db) {
        this.db
          .executeSql(
            `DELETE
             FROM ${Tables.assignmentDetails}
             WHERE id = ?`,
            [assignmentId]
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            error(err);
          });
      }
    });
  }

  public getQueueById(queueId: string): Promise<any> {
    return new Promise<any>((resolve, error) => {
      this.sqlite
        .create({
          name: this.dbName,
          location: this.dbLocation
        })
        .then(async (db: SQLiteObject) => {
          if (db) {
            db.executeSql(
              `SELECT *
               FROM ${Tables.queue}
               WHERE id = ?`,
              [queueId]
            )
              .then((resultSet) => {
                const returnItem = JSON.parse(resultSet.rows.item(0).queue);
                if (returnItem) {
                  resolve(returnItem);
                } else {
                  error('Not Found');
                }
              })
              .catch((err) => {
                error(err);
              });
          }
        });
    });
  }

  public insertQueue(queueId: string, queue: Queue): Promise<any> {
    return new Promise<any>((resolve, error) => {
      const queueAsString = JSON.stringify(queue);
      if (this.db) {
        this.db
          .executeSql(
            `INSERT INTO ${Tables.queue} (id, queue)
             VALUES (?, ?)`,
            [queueId, queueAsString]
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            error(err);
          });
      }
    });
  }

  public updateQueue(queueId: string, queue: Queue): Promise<any> {
    return new Promise<any>((resolve, error) => {
      const queueAsString = JSON.stringify(queue);
      if (this.db) {
        this.db
          .executeSql(
            `UPDATE ${Tables.queue}
             SET queue = ?
             WHERE id = ?`,
            [queueAsString, queueId]
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            error(err);
          });
      }
    });
  }

  public deleteQueue(queueId: string): Promise<any> {
    return new Promise<any>((resolve, error) => {
      if (this.db) {
        this.db
          .executeSql(
            `DELETE
             FROM ${Tables.queue}
             WHERE id = ?`,
            [queueId]
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            error(err);
          });
      }
    });
  }

  /**
   * Delete all data from db.
   */
  public deleteAllData(): Promise<void> {
    return new Promise<void>(async (resolve, error) => {
      // Get all table names
      const allTableNames = Object.values(Tables);

      // Drop every table
      for (const table of allTableNames) {
        await this.dropTable(table);
      }

      // Reinitialize db
      this.init();

      resolve();
    });
  }

  /**
   * Drop a table
   */
  private dropTable(tableName: string): Promise<any> {
    return new Promise<any>((resolve, error) => {
      if (this.db) {
        this.db
          .executeSql(`DROP TABLE IF EXISTS ${tableName}`)
          .then(
            (x) => {},
            (res) => {
              resolve(res);
            }
          )
          .catch((err) => {
            error(err);
          });
      }
    });
  }
}
