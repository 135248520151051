import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SQLite } from '@ionic-native/sqlite/ngx';
import { HttpClientModule } from '@angular/common/http';
import { Network } from '@ionic-native/network/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { OpenNativeSettings } from '@ionic-native/open-native-settings/ngx';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';

@NgModule({
  declarations: [],
  imports: [CommonModule, HttpClientModule],
  providers: [SQLite, Network, Geolocation, OpenNativeSettings, FileOpener]
})
export class CoreModule {}
